import React from 'react';
import Layout from "../../components/calculator/layout";
import SEO from "../../components/seo";

import Sidebar from "../../components/calculator/sidebar";
import Step3 from "../../components/calculator/step-3";

const CalculatorStep3 = (props) => (
    <Layout>
        <SEO title="Calculator Step 3"/>
        <div id="wrapper" class="d-flex justify-content-between">
                  <Sidebar {...props}/> 
            <Step3/>
        </div>
       
    </Layout>
)

export default CalculatorStep3;


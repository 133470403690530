/**
 * CalculatorLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

const CalculatorLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteCalculatorTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script
          src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"
          integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
          crossOrigin="anonymous"
        ></script>
        <script
          defer={false}
          src={withPrefix("bootstrap.min.js")}
          type="text/javascript"
        />
      </Helmet>

      {children}
    </>
  )
}

CalculatorLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CalculatorLayout
